import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL, GATSBY_SIGNATURE } from './config';
import { handleError } from './error';
import { GTM } from '@/utils/google-tag-manager';
import { GTMEventType } from '@/types/google-tag-manager.interface';

export const saveContact = async (contactData: ContactData) => {
  try {
    await axios.post(`${API_URL}/contacts`, {
      ...contactData,
      signature: GATSBY_SIGNATURE,
    });

    GTM.sendEvent({
      event: GTMEventType.SuccessContactRequest,
    });

    toast.success(
      'We have received your message! Our team will reach you in the next 24 hours...'
    );
  } catch (e) {
    GTM.sendEvent({
      event: GTMEventType.FailedContactRequest,
    });

    handleError(e);
  }
};

export type ContactData = {
  email: string;
  name: string;
  message: string;
  phone: string;
  company: string;
};
