// GLOBAL IMPORTS
import { useContext } from 'react'

// LOCAL IMPORTS
import config, { Config } from '@/config'
import ConfigContext from '@/context/ConfigContext'

export const useConfig = (): Config => {
  return useContext<Config>(ConfigContext)
}

export { ConfigContext }
export type { Config }
