import React, { useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { saveContact, ContactData } from '@/npc-api/contact';
import { FormProvider } from '@/hook-form';
import RHInput from '@/hook-form/RHInput';
import RHTextarea from '@/hook-form/RHTextarea';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import ReCAPTCHA from 'react-google-recaptcha';
import { useConfig } from '@/hooks/useConfig';
import { toast } from 'react-toastify';
import { GTM } from '@/utils/google-tag-manager';
import { GTMEventType } from '@/types/google-tag-manager.interface';

function MainContent() {
  const { captchaSiteKey } = useConfig();
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const ContactSchema = Yup.object().shape({
    name: Yup.string().max(50).required(`Name is required`),
    phone: Yup.string().matches(phoneRegExp, `Phone number is not valid`),
    email: Yup.string().required(`Email is required`).email(),
    company: Yup.string(),
    message: Yup.string().required(`Message is required`),
  });

  const defaultValues = useMemo(
    () => ({
      name: ``,
      phone: ``,
      email: ``,
      company: ``,
      message: ``,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm<ContactData>({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: ContactData) => {
    if (!recaptchaRef.current?.getValue()) {
      toast.error(
        `Please check the Captcha field to validate you are not a robot`
      );
      return;
    }

    GTM.sendEvent({
      event: GTMEventType.RequestContact,
    });

    await saveContact(data).then((e) =>
      reset({ name: ``, phone: ``, email: ``, company: ``, message: `` })
    );
    recaptchaRef.current?.reset();
  };

  return (
    <>
      <div className="clearfix" />
      <div className="total-media-page contact-page-div">
        <div className="container">
          <div className="row gx-5">
            <div className="col-lg-8">
              <h1>Get In Touch</h1>
              <p>
                Please feel welcome to contact our friendly reception staff with
                any general or Medical enquiry. Our doctors will receive or
                return any urgent calls.
              </p>

              <div className="con-form">
                <FormProvider
                  name="fmn"
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="d-flex align-items-center">
                          <i className="bi bi-people" />
                          <RHInput
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="d-flex align-items-center">
                          <i className="bi bi-telephone-forward" />
                          <RHInput
                            name="phone"
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="d-flex align-items-center">
                          <i className="bi bi-envelope" />
                          <RHInput
                            name="email"
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <div className="d-flex align-items-center">
                          <i className="bi bi-building" />
                          <RHInput
                            name="company"
                            type="text"
                            className="form-control"
                            placeholder="Company"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="form-group">
                        <div className="d-flex">
                          <i className="bi bi-envelope" />
                          <RHTextarea
                            className="form-control"
                            name="message"
                            placeholder="Message"
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {captchaSiteKey && (
                      <div className="col-lg-12">
                        <ReCAPTCHA
                          sitekey={captchaSiteKey}
                          ref={recaptchaRef}
                          size="normal"
                        />
                      </div>
                    )}

                    <div className="col-lg-12">
                      <button
                        disabled={isSubmitting}
                        className="btn submit-cn"
                        type="submit"
                      >
                        {`${isSubmitting ? `...Loading` : `Submit`}`}
                      </button>
                    </div>
                  </div>
                </FormProvider>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="number-div">
                <ul className="list-unstyled">
                  <li>
                    <h5>Phone</h5>
                    <p>
                      <i className="bi bi-telephone-forward" />
                      {` `}
                      <OutboundLink
                        style={{
                          textDecoration: `none`,
                          color: `black`,
                        }}
                        href={`tel:` + `+13053968973`}
                      >
                        {` `}
                        +1 (305) 396-8973 Office{` `}
                      </OutboundLink>
                    </p>
                    <p>
                      <i className="bi bi-telephone-forward" />
                      {` `}
                      <OutboundLink
                        style={{
                          textDecoration: `none`,
                          color: `black`,
                        }}
                        href={`tel:` + `+17864426465`}
                      >
                        {` `}
                        +1 (786) 442-6465 CEO Madelin Marichal{` `}
                      </OutboundLink>
                    </p>
                  </li>

                  <li>
                    <h5>Address</h5>
                    <p>
                      <i className="bi bi-geo-alt-fill" />
                      <a
                        style={{
                          textDecoration: `none`,
                          color: `black`,
                        }}
                        href="https://goo.gl/maps/43Yka5NUMQcWSBwP6"
                        target="_blank"
                        rel="noreferrer"
                      >
                        11117 W Okeechobee RD #130, Hialeah Gardens, FL 33018
                      </a>
                    </p>
                  </li>
                  <li>
                    <h5>Email</h5>
                    <p>
                      <i className="bi bi-envelope" />
                      {` `}
                      <OutboundLink
                        style={{
                          textDecoration: `none`,
                          color: `black`,
                        }}
                        href={`mailto:` + `startprofessionalcare@yahoo.com`}
                      >
                        {` `}
                        startprofessionalcare@yahoo.com{` `}
                      </OutboundLink>
                    </p>
                    <p>
                      <i className="bi bi-envelope" />
                      {` `}
                      <OutboundLink
                        style={{
                          textDecoration: `none`,
                          color: `black`,
                        }}
                        href={`mailto:` + `madelin@startprofessionalcare.com`}
                      >
                        {` `}
                        madelin@startprofessionalcare.com{` `}
                      </OutboundLink>
                    </p>
                    <h5>Fax</h5>
                    <p>
                      <i className="bi bi-envelope" />
                      {` `}
                      <OutboundLink
                        style={{
                          textDecoration: `none`,
                          color: `black`,
                        }}
                        href="fax:(305)675-2397"
                      >
                        {` `}
                        FAX(305)675-2397{` `}
                      </OutboundLink>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="g-mpa">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1874.3726761922849!2d-80.35136088757572!3d25.87570218823876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9bb6e616d5119%3A0x34cfd1a7019830b!2s11117%20W%20Okeechobee%20Rd%20%23130%2C%20Hialeah%2C%20FL%2033018%2C%20USA!5e0!3m2!1sen!2ses!4v1652882221404!5m2!1sen!2ses"
            frameBorder={0}
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
    </>
  );
}

export default MainContent;
