import React from "react";
import Layout from "@/components/layout";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Co from "@/components/Contact";
import SEO from "@/components/seo";
import { useLocation } from "@reach/router";
const Contact = () => {
  const { host } = useLocation();
  const canonical = `${host}/contact`;
  return (
    <Layout>
      <SEO title="Contact" canonical={canonical}/>
      <Header />
      <Co />
      <Footer />
    </Layout>
  );
};

export default Contact;
