import React from "react";
import BannerTop from "@/components/Contact/BannerTop";
import MainContent from "@/components/Contact/MainContent";
import Subscription from "@/components/Home/Subscription";

const Contact = () => {
  return <>
  <BannerTop/>
  <MainContent/>
  <Subscription/>
  </>;
};

export default Contact;
