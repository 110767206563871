import { Config } from '@/types'

const config: Config = {
  siteTitle: "Start Professional Care & Support",
  siteFavicon: '/favicon.ico',
  logo: '/images/logo.png',
  navbarFixedOnScroll: true,
  captchaSiteKey: '6Lc9L6UiAAAAABuxO4IqfzAj3EShYW-qoqGuFDmo',
}

export default config
export type { Config }
